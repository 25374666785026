import React from 'react';
import '../../styles/components/case-templates/banners-skin.scss';
import LogoLoader from '../Utils/LogoLoader';
import '../../styles/utils/general.scss';
import {isSmallScreen} from '../../utils/helpers';

const BannersSkin = props => {
  return (
    <div className="banner-skin-wrapper shadow">
      <div className="title-logo-container">
        <div className="case-title">
          <h1
            style={{
              color: 'white',
              marginBottom: '0',
              fontSize: !isSmallScreen () ? '58px' : '48px',
              letterSpacing: '-0.84px',
              marginTop: '0',
            }}
          >
            {props.title}
          </h1>
          <div
            className="typo-body2-white case-main-tags"
            style={{fontSize: isSmallScreen () && '15px'}}
          >
            {props.segmentTag}
          </div>

        </div>
        <div className="product-logo">
          <LogoLoader productTypeShort={props.productTypeShort} fill="white" />
          <div
            className="typo-body2-white product-type"
            style={{fontSize: isSmallScreen () && '15px'}}
          >
            {props.productType}
          </div>
          <div
            className="typo-body2-white"
            style={{fontSize: isSmallScreen () && '15px'}}
          >
            {props.areaTag}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannersSkin;
