import React, {Component} from 'react';
import {withPrefix} from 'gatsby';
import {StaticQuery, graphql} from 'gatsby';
import _ from 'lodash';
import '../../styles/components/pano/pano.scss';
import StaticBanner from '../CaseTemplates/StaticBanner';
import Banner3D from '../CaseTemplates/Banner3D';
import VideoBanner from '../CaseTemplates/VideoBanner';
import '../../styles/utils/general.scss';
import MediaLoader from '../../components/Utils/MediaLoader';
import {isTouchDevice} from '../../utils/helpers';
import ReactGA from 'react-ga';

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark {
          edges {
            node {
              id
              frontmatter {
                language
                path
                tags
                listed
                template
                productType
                productTypeShort
                segmentTag
                areaTag
                solutionTag
                title
                banner3D{
            folderName
            specsSmallScreen{
              fov
              minDistance
            }
            specsDesktop{
              fov
              minDistance
            }
          }
                panoCarouselFolder
                videoBannerId{
                  publicURL
                }
                panoBannerImage {
                  panoImage
                  hlookat
                  vlookat
                  fov
                  limitView
                }
                image {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 1036) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <PanoBanner data={data} {...props} />}
  />
);

class PanoBanner extends Component {
  constructor (props) {
    super (props);

    this.selectedRandomCase = {};
    this.xmlPano = '';

    this.state = {
      isLoaderActive: true,
      hasInteractedWithPano: false,
    };

    this.panoDiv = null;
  }

  componentDidMount () {
    const settings = {};
    const initVars = {};
    const data = this.props.data.allMarkdownRemark.edges;

    const panosComingSoon = ['cs_1.jpg', 'cs_2.jpg', 'cs_3.jpg'];

    var dataByLocale = _.chain (data)
      .filter (d => d.node.frontmatter.language === this.props.locale.locale)
      .filter (d => d.node.frontmatter.listed === true)
      .filter (d => d.node.frontmatter.template !== 'LP')
      .value ();

    //select a random case from the total list of locale filtered cases

    if (this.props.bannerXml === 'random')
      this.selectedRandomCase = this.randomCase (dataByLocale);

    //-- TO DO ---

    //make array with panos on same location of others - check md
    // ON TOP HERE CHANGE TO ONE RANDOM PANO WITH THIS FORMAT panoBannerImage: {panoImage: "AliveVision_Panorama_LIPOR1.jpg", hlookat: "0", vlookat: "0", fov: "90",limitView: "middleDown"}
    //on bottom remove "panoBannerImage"

    //if not case page randomize banners else use banner link in props

    if (this.props.bannerXml === 'random') {
      if (this.selectedRandomCase.panoBannerImage)
        this.xmlPano = this.selectedRandomCase.panoBannerImage.panoImage;
    } else {
      this.xmlPano = this.props.bannerXml;
    }

    // dinamically set initial hlookat, vlookat and fov for pano

    var initialhlookat = null;
    var initialvlookat = null;
    var initialfov = null;
    var limitView = null;

    if (
      this.props.bannerXml === 'random' &&
      this.selectedRandomCase.panoBannerImage
    ) {
      initialhlookat = this.selectedRandomCase.panoBannerImage.hlookat;
      initialvlookat = this.selectedRandomCase.panoBannerImage.vlookat;
      initialfov = this.selectedRandomCase.panoBannerImage.fov;
      limitView = this.selectedRandomCase.panoBannerImage.limitView;
    } else {
      initialhlookat = this.props.settingsPanoBannerHlookat;
      initialvlookat = this.props.settingsPanoBannerVlookat;
      initialfov = this.props.settingsPanoBannerFov;
      limitView = this.props.settingsPanoBannerLimitView;
    }

    if (initialhlookat) settings['view.hlookat'] = initialhlookat;
    if (initialvlookat) settings['view.vlookat'] = initialvlookat;
    if (initialfov) settings['view.fov'] = initialfov;

    settings['view.limitview'] = 'lookat';

    if (limitView) {
      if (limitView === 'middle') {
        settings['view.vlookatmax'] = '20';
        settings['view.vlookatmin'] = '-20';
      } else if (limitView === 'middleDown') {
        settings['view.vlookatmax'] = '90';
        settings['view.vlookatmin'] = '-20';
      } else if (limitView === 'middleUp') {
        settings['view.vlookatmax'] = '20';
        settings['view.vlookatmin'] = '-90';
      } else {
        settings['view.limitview'] = 'auto';
      }
    }

    settings['display.autofullscreen'] = 'false';
    //hide default skin

    //settings['layer[defaultskin_buttons].visible'] = false;

    //if path is root set which panner is being displayed

    if (this.props.path === '/')
      this.props.currentProjectInPanoSetter (this.selectedRandomCase);

    //set pano image to load

    if (
      this.props.bannerXml === 'random' &&
      this.selectedRandomCase.panoBannerImage
    ) {
      initVars['panofile'] =
        this.selectedRandomCase.panoCarouselFolder +
        '/' +
        this.selectedRandomCase.panoBannerImage.panoImage;
    } else if (this.props.bannerXml === 'comingSoon') {
      const panoToLoad =
        panosComingSoon[Math.floor (Math.random () * panosComingSoon.length)];

      initVars['panofile'] = 'comingsoon/' + panoToLoad;

      settings['autorotate.enabled'] = true;
      settings['autorotate.speed'] = 0.7;
    } else {
      initVars['panofile'] =
        this.props.panoCarouselFolder + '/' + this.props.bannerXml;
    }

    //attach to window krPano instance

    if (this.xmlPano)
      window.embedpano ({
        xml: withPrefix (`static/krpano/pano.xml`),
        //swf: '../../krpano/pano.swf',
        target: 'pano-banner',
        html5: 'auto',
        mobilescale: 1.0,
        consolelog: true,
        passQueryParameters: true,
        localfallback: 'http://192.168.1.74:54173',
        mwheel: false,
        initvars: initVars,
        vars: settings,
        onready: krpano_interface => {
          this.krpano = krpano_interface;
        },
      });

    setTimeout (() => {
      this.setState ({
        isLoaderActive: false,
      });
    }, 2500);

    this.panoDiv = document.getElementById ('pano-banner');

    //tracking events for GA
    let moved;
    this.downListener = () => {
      moved = false;
    };
    this.panoDiv.addEventListener ('mousedown', this.downListener);
    this.moveListener = () => {
      moved = true;
    };
    this.panoDiv.addEventListener ('mousemove', this.moveListener);
    this.upListener = () => {
      if (moved) {
        this.setState ({
          hasInteractedWithPano: true,
        });
      }
    };
    this.panoDiv.addEventListener ('mouseup', this.upListener);
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.hasInteractedWithPano !== this.state.hasInteractedWithPano) {
      if (
        this.state.hasInteractedWithPano &&
        this.props.bannerXml === 'random'
      ) {
        ReactGA.event ({
          category: 'Player360',
          action: 'hasInteractedWithBanner360inHP',
        });
      } else {
        ReactGA.event ({
          category: 'Player360',
          action: 'hasInteractedWithBanner360inShowcase',
        });

        ReactGA.event ({
          category: 'Showcase',
          action: 'hasInteractedWithBanner360',
        });
      }
    }
  }

  componentWillUnmount () {
    this.panoDiv.removeEventListener ('mousedown', this.downListener);
    this.panoDiv.removeEventListener ('mousemove', this.moveListener);
    this.panoDiv.removeEventListener ('mouseup', this.upListener);
  }

  randomCase (casesArray) {
    var arrayElement =
      casesArray[Math.floor (Math.random () * casesArray.length)];

    var videoBannerId = null;

    if (arrayElement.node.frontmatter.videoBannerId)
      videoBannerId = arrayElement.node.frontmatter.videoBannerId.publicURL;

    return {
      title: arrayElement.node.frontmatter.title,
      path: arrayElement.node.frontmatter.path,
      panoBannerImage: arrayElement.node.frontmatter.panoBannerImage,
      language: arrayElement.node.frontmatter.language,
      image: arrayElement.node.frontmatter.image,
      banner3D: arrayElement.node.frontmatter.banner3D,

      videoBannerId: videoBannerId,
      panoCarouselFolder: arrayElement.node.frontmatter.panoCarouselFolder,
    };
  }

  render () {
    return (
      <div>
        <div>
          {!this.xmlPano && this.selectedRandomCase.image
            ? this.selectedRandomCase.banner3D
                ? <Banner3D
                    banner3D={this.selectedRandomCase.banner3D}
                    scrollInteraction={this.props.scrollInteraction}
                    scrollInteractionSet={this.props.scrollInteractionSet}
                    locale={this.props.locale.locale}
                  />
                : this.selectedRandomCase.videoBannerId
                    ? <VideoBanner
                        videoBannerId={this.selectedRandomCase.videoBannerId}
                        scrollInteraction={this.props.scrollInteraction}
                        scrollInteractionSet={this.props.scrollInteractionSet}
                        locale={this.props.locale.locale}
                      />
                    : <StaticBanner
                        image={this.selectedRandomCase.image}
                        scrollInteraction={this.props.scrollInteraction}
                        locale={this.props.locale.locale}
                        scrollInteractionSet={this.props.scrollInteractionSet}
                      />
            : <div className="pano-banner-container">
                <div id="pano-banner" />
                {this.state.isLoaderActive &&
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      pointerEvents: 'none',
                    }}
                  >
                    <MediaLoader />
                  </div>}
                {typeof window !== `undefined` &&
                  isTouchDevice () &&
                  <div
                    className="banner-interaction-bottom-blocker"
                    onTouchStart={() => this.props.scrollInteractionSet ()}
                  />}
              </div>}
        </div>
      </div>
    );
  }
}

/*

     style={{
                    minHeight: typeof window !== `undefined` &&
                      isTouchDevice () &&
                      '-webkit-fill-available',
                  }}




  loadPano (e) {
    this.krpano.call ('loadpano(' + this.xmlPano + ',null,MERGE,BLEND(0.5));');
  }

  toggleFulscreen () {
    this.krpano.call ('toggle(fullscreen)');
  }


 <button onClick={e => this.loadPano (e)}>change pano</button>
        <button onClick={e => this.toggleFulscreen (e)}>fullscreen</button>


EXEMPLO DE SET A VARIAVEIS KRPANO
  removeDefaultControls () {


    if (this.krpano) {
      this.krpano.set ('layer[defaultskin_buttons].visible', false);

      // this.krpano.set ('layer[skin_btn_prev_fs].visible', false);

      if (this.krpano) {
        this.krpano.set ('view.hlookat', Math.random () * 360.0 - 180.0);
        this.krpano.set ('view.vlookat', Math.random () * 180.0 - 90.0);
        this.krpano.set ('view.fov', 80.0 + Math.random () * 100.0);
        this.krpano.set ('view.distortion', Math.random () * 1.0);
      }
    }
  }





  loadPano (e) {
    this.krpano.call ('loadpano(' + this.xmlPano + ',null,MERGE,BLEND(0.5));');
  }

  toggleFulscreen () {
    this.krpano.call ('toggle(fullscreen)');
  }





*/
