import React, { Component } from 'react'
import { auth, database } from '../../services/Firebase/loginProvider'
import { navigate } from 'gatsby'
import locales from '../../utils/constants/locales'
import * as routes from '../../utils/constants/routes'
import PasswordForget from '../Auth/PasswordForget'
import '../../styles/components/auth/sign-in.scss'

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  forgetPassActive: false,
}

class SignIn extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }
  onSubmit = event => {
    const { email, password } = this.state

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(authUser => {
        var event = new CustomEvent('closeLogin')
        // Dispatch the event
        window.dispatchEvent(event)

        database.doCreateUser(authUser.user.uid, 'username', email)

        this.setState({ ...INITIAL_STATE })

        const path = locales[this.props.locale].default
          ? routes.LANDING
          : `/${this.props.locale}${routes.LANDING}`

        navigate(path)
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  handleForgetPasswordClick = () => {
    const { forgetPassActive } = this.state

    this.setState({
      forgetPassActive: !forgetPassActive,
    })
  }

  render() {
    const { email, password, error } = this.state

    const isInvalid = password === '' || email === ''

    return this.state.forgetPassActive === false ? (
      <div className="sign-in-form-container">
        <div
          className="close-button"
          style={{ color: 'white' }}
          onClick={this.props.handleSignInVisibility}
        >
          <svg width="20px" height="20px">
            <polygon
              fill="white"
              points="16.1111111 5.11984127 14.8801587 3.88888889 10 8.76904762 5.11984127 3.88888889 3.88888889 5.11984127 8.76904762 10 3.88888889 14.8801587 5.11984127 16.1111111 10 11.2309524 14.8801587 16.1111111 16.1111111 14.8801587 11.2309524 10"
            />
          </svg>
        </div>
        <div className="sign-in-form-title" style={{ color: 'white' }}>
          LOG IN
        </div>
        <div className="sign-in-input-wrapper">
          <form onSubmit={this.onSubmit}>
            <label htmlFor="input" className="sign-in-form-label">
              Email
            </label>

            <input
              className="user-input typo-body2-white"
              name="email"
              value={email}
              onChange={event =>
                this.setState({ [event.target.name]: event.target.value })
              }
              type="text"
            />
            <label htmlFor="input" className="sign-in-form-label">
              Password
            </label>
            <input
              className="pass-input typo-body2-white"
              name="password"
              value={password}
              onChange={event =>
                this.setState({ [event.target.name]: event.target.value })
              }
              type="password"
              autoComplete="new-password"
            />

            <div
              className="forget-password-link"
              onClick={this.handleForgetPasswordClick}
            >
              {this.props.locale === 'en'
                ? 'forgot password?'
                : 'esqueceu-se da password?'}
            </div>

            {error && (
              <p style={{ marginBottom: 0 }} className="typo-captions-red">
                {error.message}
              </p>
            )}
            <button
              disabled={isInvalid}
              type="submit"
              className="button-primary sign-in-form-button"
            >
              {this.props.locale === 'en' ? 'Sign In' : 'Entrar'}
            </button>
          </form>
        </div>
      </div>
    ) : (
      <PasswordForget
        handleForgetPasswordVisibility={this.handleForgetPasswordClick}
        locale={this.props.locale}
      />
    )
  }
}

export default SignIn

/*

    onClick={
                this.state.error !== null
                  ? undefined
                  : this.props.changeHamburguerStatus
              }

*/
