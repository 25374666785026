module.exports = {
  _pathPrefix: '/int_alivevision_website', // NAO MEXER //dev
  _title: 'Alive Vision', // Navigation and Site Title
  _titleAlt: 'Alive Vision', // Title for JSONLD
  description: 'AliveVision is an online platform and also an innovation of integration, communication, and valorisation of advanced visual contents developed by 3Decide. ',
  _url: 'https://alive.vision', // NAO MEXER
  siteLanguage: 'en', // Language Tag on <html> element
  logo: '/logos/alive_logo_600px', // NAO MEXER

  GAnalytics: 'UA-115175661-1', //ID do Google Analytics

  // JSONLD / Manifest | configs for manifest JSON PWA
  favicon: 'src/favicon.png', // NAO MEXER
  shortName: 'Alive Vision', // shortname para PWA no telemovel. MUST be shorter than 12 characters
  author: '3Decide', // Author for schemaORGJSONLD

  background_color: '#663399', //NAO MEXER
  theme_color: '#663399', // NAO MEXER
};
