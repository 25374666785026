import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Initialize Firebase
var config = {
  apiKey: 'AIzaSyB-hHYOfZafkeviawEKDhVHe-yH4lB6SRU',
  authDomain: 'alive-vision-1521207421838.firebaseapp.com',
  databaseURL: 'https://alive-vision-1521207421838.firebaseio.com',
  projectId: 'alive-vision-1521207421838',
  storageBucket: 'alive-vision-1521207421838.appspot.com',
  messagingSenderId: '625511186555',
};

firebase.initializeApp(config);

const database = firebase.database();

const authentication = firebase.auth();

export {authentication,
        database        
};
