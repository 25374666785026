import React from 'react';
import '../../styles/components/pano/krpano-interaction-logo.scss';


export default function KrPanoInteractionLogo({
  panoInteraction,
  locale,
  panoBannerImage,
  path
}) {
  var krpanoIconIsHidden = panoInteraction === true ? 'is-hidden' : '';

  const messages = locale === 'en'
    ? 'Click and drag to explore image'
    : 'Clique e arraste para explorar a imagem';

  return (
    <div className="krpano-activation-wrapper shadow">
      {panoBannerImage || path !== '/'
        ? <>
            <div className="krpano-activation">
              <div className={'krpano-activation-icon ' + krpanoIconIsHidden}>
                <div className="krpano-interaction-arrow-left">
                  <svg width="36" height="36">
                    <polygon
                      fill="#D8D8D8"
                      points="25 2 9 18 25 34 27.1669983 31.8330017 13.3339966 18 27.1669983 4.1669983"
                    />
                  </svg>
                </div>
                <div className="krpano-interaction-central-icon">
                  <svg width="50" height="50">
                    <path
                      fill="#D8D8D8"
                      d="M39.7527049,29.1666667 L36.1111111,29.1666667 L41.0112897,22.2222222 L42.9800374,25 L43.0555556,25 C43.0555556,25.0354059 43.0554609,25.0707897 43.0552719,25.1061511 L45.9331589,29.1666667 L42.608084,29.1666667 C40.7006667,37.9032834 32.9197739,44.4444444 23.6111111,44.4444444 C12.872241,44.4444444 4.16666667,35.7388701 4.16666667,25 C4.16666667,14.2611299 12.872241,5.55555556 23.6111111,5.55555556 C30.8083007,5.55555556 37.0921991,9.46582001 40.4542351,15.2777778 L37.1498684,15.2777778 C34.1243734,11.0720316 29.1875709,8.33333333 23.6111111,8.33333333 C14.4063653,8.33333333 6.94444444,15.7952542 6.94444444,25 C6.94444444,34.2047458 14.4063653,41.6666667 23.6111111,41.6666667 C31.3771087,41.6666667 37.9025266,36.3551134 39.7527049,29.1666667 Z M23.6111111,33.3333333 C19.0087382,33.3333333 15.2777778,29.6023729 15.2777778,25 C15.2777778,20.3976271 19.0087382,16.6666667 23.6111111,16.6666667 C28.213484,16.6666667 31.9444444,20.3976271 31.9444444,25 C31.9444444,29.6023729 28.213484,33.3333333 23.6111111,33.3333333 Z"
                    />
                  </svg>
                </div>
                <div className="krpano-interaction-arrow-right">
                  <svg width="36" height="36">
                    <polygon
                      fill="#D8D8D8"
                      points="11.1669983 2 27.1669983 18 11.1669983 34 9 31.8330017 22.8330017 18 9 4.1669983"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className={'krpano-activation-caption'}>
              <p className={krpanoIconIsHidden}>{messages}</p>
            </div>

          </>
        : <></>}
    </div>
  );
}
