import React from 'react';
import {withPrefix} from 'gatsby';
import MediaLoader from '../../Utils/MediaLoader';

// oraculo e responsividade na galeria 360 stretch
// landscape mode

class Viewer3D extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      isNodeLoaded: false,
      containerWidth: null,
      containerHeight: null,
      isFakeLoaderActive: false,
    };

    this.sdk = null;
    this.divMount = null;
    this.container = null;
  }

  //https://gist.github.com/jacob-beltran/aa114af1b6fd5de866aa365e3763a90b - animation frame

  componentDidMount () {
    this.render3DModel ();
    this.container = document.getElementsByClassName ('component3D-frame')[0];

    this.setState ({
      containerWidth: this.container.clientWidth,
      containerHeight: this.container.clientHeight,
    });

    window.addEventListener ('resize', this.reportWindowSize);
  }

  reportWindowSize = () => {
    this.setState ({
      containerWidth: this.container.clientWidth,
      containerHeight: this.container.clientHeight,
      isFakeLoaderActive: true,
    });
  };

  componentDidUpdate (prevProps, prevState) {
    if (
      prevProps.dataSource.url !== this.props.dataSource.url ||
      prevState.containerWidth !== this.state.containerWidth ||
      prevState.containerHeight !== this.state.containerHeight
    ) {
      this.render3DModel ();
    }
  }

  render3DModel = () => {
    var data = this.props;

    if (this.state.isFakeLoaderActive)
      setInterval (() => {
        this.setState ({isFakeLoaderActive: false});
      }, 7000);

    //remove script from DOM before adding again

    var oldScript = document.getElementById ('lib');
    if (oldScript) oldScript.parentNode.removeChild (oldScript);

    //free memory from previous WEBGL instances

    if (
      document.getElementsByTagName ('canvas') &&
      document.getElementsByTagName ('canvas')[0]
    ) {
      if (document.getElementsByTagName ('canvas').length > 1) {
        this.currentWebGLContext = document
          .getElementsByTagName ('canvas')[1]
          .getContext ('webgl');

        this.currentWebGLContext.getExtension ('WEBGL_lose_context') &&
          this.currentWebGLContext
            .getExtension ('WEBGL_lose_context')
            .loseContext ();
      } else {
        this.currentWebGLContext = document
          .getElementsByTagName ('canvas')[0]
          .getContext ('webgl');

        this.currentWebGLContext.getExtension ('WEBGL_lose_context') &&
          this.currentWebGLContext
            .getExtension ('WEBGL_lose_context')
            .loseContext ();
      }
    }

    var script = document.createElement ('script');
    script.setAttribute ('src', withPrefix ('/3dmc.0.2.8.js'));
    script.setAttribute ('id', 'lib');

    script.onload = () => {
      window.mcConfig.autoDeploy = false;

      this.divMount = this.props.isBanner ? 'banner3D' : 'component3D';

      window.mountMediaComponent (this.divMount);

      this.sdk = new window.DecideSDK ();

      this.sdk.showViewer (this.sdk.listAvailableViewers ()[0]);

      this.sdk._3DViewer.loadConfiguration ('mcConfig', {autoDeploy: false});

      this.sdk._3DViewer.loadConfiguration ('dataSource', data.dataSource);

      this.sdk._3DViewer.loadConfiguration ('renderer', {
        ...data.renderer,

        width: this.state.containerWidth,
        height: this.state.containerHeight,
      });

      this.sdk._3DViewer.loadConfiguration ('camera', data.camera);

      this.sdk._3DViewer.loadConfiguration ('light', data.light);

      this.sdk._3DViewer.loadConfiguration ('style', data.style);

      this.sdk._3DViewer.getCameraAPI ().setAutoRotate (true);

      //tracking of events for GA

      let moved;
      this.downListener = () => {
        moved = false;

        this.sdk._3DViewer.getCameraAPI ().setAutoRotate (false);
      };

      this.moveListener = () => {
        moved = true;
      };

      this.upListener = () => {
        setTimeout (
          () => this.sdk._3DViewer.getCameraAPI ().setAutoRotate (true),
          2000
        );
        if (moved) {
          this.props.hasInteractedWithPlayer ();
        }
      };

      document
        .getElementById (this.divMount)
        .addEventListener ('mouseup', this.upListener);
      document
        .getElementById (this.divMount)
        .addEventListener ('mousedown', this.downListener);
      document
        .getElementById (this.divMount)
        .addEventListener ('mousemove', this.moveListener);

      this.sdk._3DViewer.addEventListener ('onNodeReady', targetNode => {
        this.setState ({
          isNodeLoaded: true,
        });
      });
    };

    script.onerror = () => {
      console.info ('Error on loading  script...');
    };

    document.body.appendChild (script);
  };

  componentWillUnmount () {
    if (document.getElementsByTagName ('canvas')) {
      for (
        let i = 0;
        i < document.getElementsByTagName ('canvas').length;
        i++
      ) {
        this.currentWebGLContext = document
          .getElementsByTagName ('canvas')[i]
          .getContext ('webgl');

        this.currentWebGLContext.getExtension ('WEBGL_lose_context') &&
          this.currentWebGLContext
            .getExtension ('WEBGL_lose_context')
            .loseContext ();
      }
    }

    document
      .getElementById (this.divMount)
      .removeEventListener ('mousedown', this.downListener);
    document
      .getElementById (this.divMount)
      .removeEventListener ('mousemove', this.moveListener);
    document
      .getElementById (this.divMount)
      .removeEventListener ('mouseup', this.upListener);

    window.removeEventListener ('resize', this.reportWindowSize);
  }

  render () {
    const playerDivId = this.props.isBanner ? 'banner3D' : 'component3D';

    return (
      <div
        className="3d-viewer-container"
        style={{width: '100%', height: '100%'}}
      >
        {!this.state.isNodeLoaded && <MediaLoader />}
        {this.state.isFakeLoaderActive && <MediaLoader />}
        <div
          id={playerDivId}
          style={{
            display: this.state.isNodeLoaded ? 'block' : 'none',
          }}
        />
      </div>
    );
  }
}

export default Viewer3D;

/*

class Viewer3D extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      isNodeLoaded: false,
      containerWidth: null,
      containerHeight: null,
      isFakeLoaderActive: false,
    };

    this.sdk = null;
    this.divMount = null;
    this.container = null;
    this.containerWidth = null;
    this.containerHeight = null;
  }

  //https://gist.github.com/jacob-beltran/aa114af1b6fd5de866aa365e3763a90b - animation frame

  componentDidMount () {
    console.log ('DID MOUNT');

    this.render3DModel ();
    this.container = document.getElementsByClassName ('component3D-frame')[0];

    this.containerWidth = this.container.clientWidth;
    this.containerHeight = this.container.clientHeight;
    window.addEventListener ('resize', this.reportWindowSize);
  }

  reportWindowSize = () => {
    // this.setState ({
    //   containerWidth: this.container.clientWidth,
    //   containerHeight: this.container.clientHeight,
    //   isFakeLoaderActive: true,
    // });

    this.containerWidth = this.container.clientWidth;
    this.containerHeight = this.container.clientHeight;

    this.render3DModel ();
  };

  componentDidUpdate (prevProps, prevState) {
    if (
      prevProps.dataSource.url !== this.props.dataSource.url //||
      // prevState.containerWidth !== this.state.containerWidth ||
      // prevState.containerHeight !== this.state.containerHeight
    ) {
      console.log ('DID UPDATE');

      this.render3DModel ();
    }
  }

  render3DModel = () => {
    var data = this.props;

    if (this.state.isFakeLoaderActive)
      setInterval (() => {
        this.setState ({isFakeLoaderActive: false});
      }, 5000);

    //remove script from DOM before adding again

    var oldScript = document.getElementById ('lib');
    if (oldScript) oldScript.parentNode.removeChild (oldScript);

    //free memory from previous WEBGL instances

    if (
      document.getElementsByTagName ('canvas') &&
      document.getElementsByTagName ('canvas')[0]
    ) {
      if (document.getElementsByTagName ('canvas').length > 1) {
        this.currentWebGLContext = document
          .getElementsByTagName ('canvas')[1]
          .getContext ('webgl');

        this.currentWebGLContext.getExtension ('WEBGL_lose_context') &&
          this.currentWebGLContext
            .getExtension ('WEBGL_lose_context')
            .loseContext ();
      } else {
        this.currentWebGLContext = document
          .getElementsByTagName ('canvas')[0]
          .getContext ('webgl');

        this.currentWebGLContext.getExtension ('WEBGL_lose_context') &&
          this.currentWebGLContext
            .getExtension ('WEBGL_lose_context')
            .loseContext ();
      }
    }

    var script = document.createElement ('script');
    script.setAttribute ('src', withPrefix ('/3dmc.0.2.8.js'));
    script.setAttribute ('id', 'lib');

    script.onload = () => {
      window.mcConfig.autoDeploy = false;

      this.divMount = this.props.isBanner ? 'banner3D' : 'component3D';

      window.mountMediaComponent (this.divMount);

      this.sdk = new window.DecideSDK ();

      this.sdk.showViewer (this.sdk.listAvailableViewers ()[0]);

      this.sdk._3DViewer.loadConfiguration ('mcConfig', {autoDeploy: false});

      this.sdk._3DViewer.loadConfiguration ('dataSource', data.dataSource);

      this.sdk._3DViewer.loadConfiguration ('renderer', {
        ...data.renderer,

        width: this.containerWidth,
        height: this.containerHeight,
      });

      this.sdk._3DViewer.loadConfiguration ('camera', data.camera);

      this.sdk._3DViewer.loadConfiguration ('light', data.light);

      this.sdk._3DViewer.loadConfiguration ('style', data.style);

      this.sdk._3DViewer.getCameraAPI ().setAutoRotate (true);

      //tracking of events for GA

      let moved;
      this.downListener = () => {
        moved = false;

        this.sdk._3DViewer.getCameraAPI ().setAutoRotate (false);
      };

      this.moveListener = () => {
        moved = true;
      };

      this.upListener = () => {
        setTimeout (
          () => this.sdk._3DViewer.getCameraAPI ().setAutoRotate (true),
          2000
        );
        if (moved) {
          this.props.hasInteractedWithPlayer ();
        }
      };

      document
        .getElementById (this.divMount)
        .addEventListener ('mouseup', this.upListener);
      document
        .getElementById (this.divMount)
        .addEventListener ('mousedown', this.downListener);
      document
        .getElementById (this.divMount)
        .addEventListener ('mousemove', this.moveListener);

      this.sdk._3DViewer.addEventListener ('onNodeReady', targetNode => {
        this.setState ({
          isNodeLoaded: true,
        });
      });
    };

    script.onerror = () => {
      console.info ('Error on loading  script...');
    };

    document.body.appendChild (script);
  };

  componentWillUnmount () {
    if (document.getElementsByTagName ('canvas')) {
      for (
        let i = 0;
        i < document.getElementsByTagName ('canvas').length;
        i++
      ) {
        this.currentWebGLContext = document
          .getElementsByTagName ('canvas')[i]
          .getContext ('webgl');

        this.currentWebGLContext.getExtension ('WEBGL_lose_context') &&
          this.currentWebGLContext
            .getExtension ('WEBGL_lose_context')
            .loseContext ();
      }
    }

    document
      .getElementById (this.divMount)
      .removeEventListener ('mousedown', this.downListener);
    document
      .getElementById (this.divMount)
      .removeEventListener ('mousemove', this.moveListener);
    document
      .getElementById (this.divMount)
      .removeEventListener ('mouseup', this.upListener);

    window.removeEventListener ('resize', this.reportWindowSize);
  }

  render () {
    const playerDivId = this.props.isBanner ? 'banner3D' : 'component3D';

    console.log ('RENDER');
    return (
      <div
        className="3d-viewer-container"
        style={{width: '100%', height: '100%'}}
      >
        {!this.state.isNodeLoaded && <MediaLoader />}
        {this.state.isFakeLoaderActive && <MediaLoader />}
        <div
          id={playerDivId}
          style={{
            display: this.state.isNodeLoaded ? 'block' : 'none',
          }}
        />
      </div>
    );
  }
}

export default Viewer3D;



*/
