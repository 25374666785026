import React, { useEffect } from 'react'
//import PropTypes from 'prop-types'
//import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from './Header/header'
import '../styles/components/layout.scss'
import SEO from './SEO/seo-provider'
import withAuthentication from './Session/WithAuthentication'
import AuthUserContext from './Session/AuthUserContext'
import Footer from './footer'
import '../styles/utils/general.scss'
import { GAnalytics } from '../utils/site-config'
import ReactGA from 'react-ga';
import GDPR from './gdpr'


//TODO usar grid para sobrepor div da header com a div da children

const {
  Provider: LocaleProvider,
  Consumer: LocaleConsumer,
} = React.createContext()

const Layout = ({ children, path, locale, headerLogoColor, templateType }) => {


  function setDocHeight() {
    typeof window !== `undefined` && document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  };

  setDocHeight();

  if (typeof window !== `undefined`) {
    window.addEventListener('resize', setDocHeight)
    window.addEventListener('orientationchange', setDocHeight)
  }



  useEffect(() => {
    ReactGA.initialize(GAnalytics);
  }, []);


  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => {
        return(
        <>
          <SEO />
          <div className="main-container">
            <LocaleProvider value={locale}>
              <div className="header--layout">
                <AuthUserContext.Consumer>
                    {
                      (authUser) => {
                        

                        return(
                        <Header
                          siteTitle={data.site.siteMetadata.title}
                          logoColor={headerLogoColor}
                          path={path}
                            locale={locale}
                            templateType={templateType}
                          />
                        )
                      }}
                </AuthUserContext.Consumer>
              </div>

              <div className="page-content--layout">{children}</div>
                { path !== "/"
                  ? <GDPR locale={locale} />
                  : <GDPR locale={locale.locale} />
                }
                {templateType !== "LP" &&
                  <Footer locale={locale} />
                }
            
            </LocaleProvider>
          </div>
        </>)
}}
    />
  )
}

export { LocaleConsumer }

Layout.defaultProps = {
  path: '/',
}

export default withAuthentication(Layout)




/*
  < div className = "mobile-unavailable" >
    <div className="alive-logo-mobile-unavailable"><img className="shadow" src={aliveBanner} alt="alive-banner" /></div>
    <div className="mobile-unavailable-text typo-body-standart-bold "><h2>{locale.locale && locale.locale === "en" ? "Mobile version is coming soon" : "A versão mobile está para breve"}</h2></div>
    <div className="mobile-unavailable-redirect typo-body-standart-bold">{
      locale.locale && locale.locale === 'en' ? <>You can experience our site on tablet or desktop devices. Know us better in <a href="http://www.3decide.com" style={{ color: "#ff6b6b", textDecoration: "none", cursor: "pointer" }}>www.3decide.com</a>
      </> : <>Pode visitar o nosso site através de um tablet ou desktop. Conheça-nos melhor em <a href="http://www.3decide.com" style={{ color: "#ff6b6b", textDecoration: "none", cursor: "pointer" }}>www.3decide.com</a></>
    }</div>
              </div >

*/

