import React, {useState} from 'react';
import '../styles/components/gdpr.scss';
import '../styles/utils/general.scss';

const GDPR = ({locale}) => {
  const [hasDismissedNotice, setHasDismissedNotice] = useState (false);

  const noticeText = locale === 'en'
      ? 'We use cookies to enhance your user experience and improve the quality of our website.'
      : 'Usamos cookies para melhorar a sua experiência e a qualidade do nosso site.'


  const buttonText = locale === 'en' ? 'ACCEPT COOKIES' : 'ACEITAR COOKIES';

  return (
    <>
      {!hasDismissedNotice &&
        <div div className="gdpr-notice-container">
          <div className="gdpr-notice-text gdpr-text-body">{noticeText}</div>
          <div className="gdpr-button button-footer" onClick={ () => setHasDismissedNotice(true)} style={{lineHeight: "16px", fontSize: "11px",  width: "196px", fontFamily: "Nunito Sans", fontWeight: "700", letterSpacing: "0.0125em", display: "flex", alignItems:"center", justifyContent: "center"}}>
            <div>{buttonText}</div>
          </div>
        </div>}
    </>
  );
};

export default GDPR;



