import React from 'react';

export default function AllProducts (props) {

  const iconFill = props.iconFill;
  return (
    <>
      <svg
        className="all-cases"
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        aria-labelledby="title"
      >
        <title id="title">IconAllCases</title>
        <path
          fill={iconFill}
          d="M4,9 L12,9 L12,17 L4,17 L4,9 Z M4,20 L12,20 L12,28 L4,28 L4,20 Z M14,9 L22,9 L22,17 L14,17 L14,9 Z M14,20 L22,20 L22,28 L14,28 L14,20 Z M24,9 L32,9 L32,17 L24,17 L24,9 Z M24,20 L32,20 L32,28 L24,28 L24,20 Z"
        />
      </svg>
    </>
  );
}

/*
<path
  fill={iconFill}
  d="M9.36,7.92 L18,12.9601107 L9.36,18 L9.36,7.92 Z M0.72,12.96 L9.36,18.0001107 L0.72,23.04 L0.72,12.96 Z M9.36,18 L18,23.0401107 L9.36,28.08 L9.36,18 Z M26.64,7.92 L26.64,18 L18,12.9601107 L26.64,7.92 Z M35.28,12.96 L35.28,23.04 L26.64,18.0001107 L35.28,12.96 Z M26.64,18 L26.64,28.08 L18,23.0401107 L26.64,18 Z"
/>;
*/

/*
<svg
  width="36px"
  height="22px"
  viewBox="0 0 36 22"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <defs>
    <path
      d="M9.36,7.92 L18,12.9601107 L9.36,18 L9.36,7.92 Z M0.72,12.96 L9.36,18.0001107 L0.72,23.04 L0.72,12.96 Z M9.36,18 L18,23.0401107 L9.36,28.08 L9.36,18 Z M26.64,7.92 L26.64,18 L18,12.9601107 L26.64,7.92 Z M35.28,12.96 L35.28,23.04 L26.64,18.0001107 L35.28,12.96 Z M26.64,18 L26.64,28.08 L18,23.0401107 L26.64,18 Z"
      id="path-1"
    />
  </defs>
  <g
    id="Symbols"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="ui-/-buttons-/-circle-logopedia-management"
      transform="translate(-7.000000, -14.000000)"
    >
      <g
        id="ui-/-icon-/-logopedia-/-management_50px"
        transform="translate(7.000000, 7.000000)"
      >
        <mask id="mask-2" fill="white">
          <use xlink:href="#path-1" />
        </mask>
        <use
          id="Combined-Shape"
          fill="#0033A0"
          fill-rule="evenodd"
          xlink:href="#path-1"
        />
        <g
          id="color-/-white-/-neutral-main-"
          mask="url(#mask-2)"
          fill="#FFFFFF"
          fill-rule="evenodd"
        >
          <rect id="Rectangle-Copy-3" x="0" y="0" width="36" height="36" />
        </g>
      </g>
    </g>
  </g>
</svg>;

*/
