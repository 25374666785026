import React from 'react';
import Component3D from './component3D/Component3D';
import BannersSkin from './BannersSkin';
import '../../styles/components/case-templates/banner-3d.scss';
import ScrollInteractionLogo from '../Pano/ScrollInteractionLogo';
import {isTouchDevice} from '../../utils/helpers'

function Banner3D({
  banner3D,
  title,
  productTypeShort,
  segmentTag,
  areaTag,
  solutionTag,
  productType,
  scrollInteraction,
  locale,
  scrollInteractionSet
}) {
  



  return (
    <div className="banner-3d-wrapper">
      <Component3D project3D={banner3D} isBanner={true} scrollInteraction={scrollInteraction} scrollInteractionSet={scrollInteractionSet} isHPBanner={!title ? true : undefined}  />
      {title && //if there is no title it is a HP banner
        <>
        <BannersSkin
          title={title}
          productTypeShort={productTypeShort}
          segmentTag={segmentTag}
          areaTag={areaTag}
          solutionTag={solutionTag}
          productType={productType}
        />
        {title && typeof window !== `undefined` && isTouchDevice() && 
        <div
          className="banner-interaction-bottom-blocker"
          onTouchStart={ () => scrollInteractionSet()}
        />  
      }
        <ScrollInteractionLogo scrollInteraction={scrollInteraction} locale={locale} scrollInteractionSet={scrollInteractionSet} />
      </>
        }
    </div>
  );
}

export default Banner3D;
