import React from 'react';

export default function AdvancedVisualContent (props) {
  const circleFill = props.iconFill;

  const iconFill = props.iconFill === 'white' ? '#0033A0' : 'white';

  return (
    <>
      {!props.isLogoMenu
        ? <svg
            className="advanced-visual-content"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            aria-labelledby="title"
          >
            <title id="title">Advanced Visual Content</title>
            <path
              fill={circleFill}
              d="M18,18 L26.64,23.0401107 L18,28.08 L9.36,23.0401107 L18,18 Z M18,7.92 L26.64,12.9601107 L18,18 L9.36,12.9601107 L18,7.92 Z M35.28,7.92 L35.28,18 L26.64,12.9601107 L35.28,7.92 Z M35.28,18 L35.28,28.08 L26.64,23.0401107 L35.28,18 Z M0.72,7.92 L9.36,12.9601107 L0.72,18 L0.72,7.92 Z M0.72,18 L9.36,23.0401107 L0.72,28.08 L0.72,18 Z"
            />
          </svg>
        : <svg
            className="advanced-visual-content shadow-button"
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            aria-labelledby="title"
          >
            <title id="title">Advanced Visual Content</title>

            <circle
              fill={circleFill}
              id="path-1"
              cx="24"
              cy="24"
              r="24"
              transform="translate(1.000000, 1.000000)"
            />
            <path
              fill={iconFill}
              d="M18,18 L26.64,23.0401107 L18,28.08 L9.36,23.0401107 L18,18 Z M18,7.92 L26.64,12.9601107 L18,18 L9.36,12.9601107 L18,7.92 Z M35.28,7.92 L35.28,18 L26.64,12.9601107 L35.28,7.92 Z M35.28,18 L35.28,28.08 L26.64,23.0401107 L35.28,18 Z M0.72,7.92 L9.36,12.9601107 L0.72,18 L0.72,7.92 Z M0.72,18 L9.36,23.0401107 L0.72,28.08 L0.72,18 Z"
              transform="translate(7.000000, 7.000000)"
            />

          </svg>}
    </>
  );
}
