import React from 'react'
import AdvancedVisualContentLogo from '../ProductTypeIcons/AdvancedVisualContent'
import ImmersiveandVirtualPresentationsLogo from '../ProductTypeIcons/ImmersiveandVirtualPresentations'
import InteractiveExperiencesLogo from '../ProductTypeIcons/InteractiveExperiences'
import ManagementandLogisticsLogo from '../ProductTypeIcons/ManagementandLogistics'
import AllProducts from '../ProductTypeIcons/AllProducts';

const LogoLoader = ({ productTypeShort, fill, isLogoMenu }) => {
  let productLogo = null
  let iconFill = '';

  if(fill){
    iconFill = fill
  }else{
    iconFill = '#0033A0'
  }
  


  switch (productTypeShort) {
    case 'all':
      productLogo = <AllProducts iconFill={iconFill} />
      break
    case 'avc':
      productLogo = <AdvancedVisualContentLogo iconFill={iconFill} isLogoMenu = {isLogoMenu}/>
      break
    case 'ivp':
      productLogo = (
        <ImmersiveandVirtualPresentationsLogo isLogoMenu={isLogoMenu} iconFill={iconFill} />
      )
      break
    case 'ie':
      productLogo = <InteractiveExperiencesLogo iconFill={iconFill} isLogoMenu = {isLogoMenu}/>
      break
    case 'mls':
      productLogo = <ManagementandLogisticsLogo iconFill={iconFill} isLogoMenu = {isLogoMenu}/>
      break
    default:
      break
  }

  return <>{productLogo}</>
}

export default LogoLoader
