import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import locales from '../../utils/constants/locales'

const LocalizedLink = ({ to, ...props }) => {
  const path = locales[props.locale].default ? to : `/${props.locale}${to}`

  return <Link {...props} to={path} />
}
LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
}

LocalizedLink.defaultProps = {
  locale: 'en',
}

export default LocalizedLink
