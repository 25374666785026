import React from 'react';
import '../../../styles/components/case-templates/component3D.scss';
import '../../../styles/utils/general.scss';
import Viewer3D from './Viewer3D';
import {withPrefix} from 'gatsby';
import {isSmallScreen} from '../../../utils/helpers';
import ReactGA from 'react-ga';

class Component3D extends React.PureComponent {
  state = {
    currentProject: 0,
    hasInteractedWithPlayer: false,
  };

  changeDataSource = direction => {
    if (direction === 'next') {
      if (this.state.currentProject < this.props.project3D.length - 1) {
        this.setState ({
          currentProject: this.state.currentProject + 1,
        });
      } else {
        this.setState ({
          currentProject: 0,
        });
      }
    }
    if (direction === 'previous') {
      if (this.state.currentProject > 1) {
        this.setState ({
          currentProject: this.state.currentProject - 1,
        });
      } else {
        this.setState ({
          currentProject: this.props.project3D.length,
        });
      }
    }
  };

  hasInteractedWithPlayer = () =>
    this.setState ({hasInteractedWithPlayer: true});

  componentDidUpdate (prevProps, prevState) {
    if (
      prevState.hasInteractedWithPlayer !== this.state.hasInteractedWithPlayer
    ) {
      if (this.props.isBanner && this.props.isHPBanner === true) {
        ReactGA.event ({
          category: 'Player3D',
          action: 'hasInteractedWithBanner3DinHP',
        });
      } else if (this.props.isBanner) {
        ReactGA.event ({
          category: 'Player3D',
          action: 'hasInteractedWithBanner3DinShowcase',
        });

        ReactGA.event ({
          category: 'Showcase',
          action: 'hasInteractedWithBanner3D',
        });
      } else {
        ReactGA.event ({
          category: 'Player3D',
          action: 'hasInteractedWithComponent3D',
        });
      }
    }
  }

  getPolarAngleRange = range => {
    var minPolarAngle, maxPolarAngle;

    if (range === 'middle') {
      minPolarAngle = 30;
      maxPolarAngle = 60;
    } else if (range === 'middleDown') {
      minPolarAngle = 30;
      maxPolarAngle = 90;
    } else if (range === 'middleUp') {
      minPolarAngle = 0;
      maxPolarAngle = 30;
    } else {
      minPolarAngle = 0;
      maxPolarAngle = 90;
    }

    return {
      minPolarAngle,
      maxPolarAngle,
    };
  };

  render () {
    const backgroundImageURL = withPrefix (
      `/LocalViewer3D/images/${this.props.backgroundImage3D}`
    );

    const cameraCustomSpecs = isSmallScreen ()
      ? {
          fov: this.props.project3D[this.state.currentProject].specsSmallScreen
            .fov,
          minDistance: this.props.project3D[this.state.currentProject]
            .specsSmallScreen.minDistance,
          initialPosition: {
            ...this.props.project3D[this.state.currentProject].specsSmallScreen
              .initialPosition,
          },
          initialTarget: {
            ...this.props.project3D[this.state.currentProject].specsSmallScreen
              .initialTarget,
          },
          minPolarAngle: this.getPolarAngleRange (
            this.props.project3D[this.state.currentProject].specsSmallScreen
              .polarAngleRange
          ).minPolarAngle,
          maxPolarAngle: this.getPolarAngleRange (
            this.props.project3D[this.state.currentProject].specsSmallScreen
              .polarAngleRange
          ).maxPolarAngle,
        }
      : {
          fov: this.props.project3D[this.state.currentProject].specsDesktop.fov,
          minDistance: this.props.project3D[this.state.currentProject]
            .specsDesktop.minDistance,
          initialPosition: {
            ...this.props.project3D[this.state.currentProject].specsDesktop
              .initialPosition,
          },
          initialTarget: {
            ...this.props.project3D[this.state.currentProject].specsDesktop
              .initialTarget,
          },
          minPolarAngle: this.getPolarAngleRange (
            this.props.project3D[this.state.currentProject].specsDesktop
              .polarAngleRange
          ).minPolarAngle,
          maxPolarAngle: this.getPolarAngleRange (
            this.props.project3D[this.state.currentProject].specsDesktop
              .polarAngleRange
          ).maxPolarAngle,
        };

    const rendererCustomSpecs = isSmallScreen ()
      ? {
          enableWireframe: this.props.project3D[this.state.currentProject]
            .specsSmallScreen.enableWireframe,
        }
      : {
          enableWireframe: this.props.project3D[this.state.currentProject]
            .specsDesktop.enableWireframe,
        };

    const dataSource = {
      url: withPrefix (
        `/LocalViewer3D/projects/${this.props.project3D[this.state.currentProject].folderName}/`
      ),
      enableDoubleSide: false,
    };

    const style = {
      backgroundImage: `url(${backgroundImageURL})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      width: '100%',
      height: 'auto',
    };

    const wrapperBannerSize =
      typeof window !== `undefined` && window.innerHeight;

    return (
      <div
        className="component3D-wrapper"
        style={
          this.props.isBanner
            ? {
                height: wrapperBannerSize,
              }
            : this.props.project3D && {
                marginTop: this.props.paddingTop,
                marginBottom: this.props.paddingBottom,
                height: '800px',
              }
        }
      >

        {this.props.isBanner
          ? <div
              className="symbol3D-container-banner"
              style={{padding: 0, height: 0}}
            />
          : this.props.project3D &&
              <div className="symbol3D-container">
                <svg width="70px" height="70px">
                  <path
                    fill="white"
                    d="M59.5837231,55.2074983 L50.1912765,50.0011801 L36.9444444,57.2668391 L36.9444444,42.5052203 L51.8393829,33.9056236 L51.8393829,46.4683635 L60.6711778,51.3639073 L60.6711778,44.7222222 L64.1666667,44.7222222 L64.1666667,56.621262 L54.4444444,61.2141628 L52.6514101,58.3333333 L59.5837231,55.2074983 Z M9.32882222,51.3278831 L18.1606171,46.4323393 L18.1606171,34.3661852 L33.0555556,42.5584013 L33.0555556,57.2668391 L19.7760571,49.9832632 L10.3281258,55.2203371 L16.5104529,58.3333333 L14.7174185,61.2141628 L5.83333333,56.621262 L5.83333333,44.7222222 L9.32882222,44.7222222 L9.32882222,51.3278831 Z M36.9444444,11.8580416 L36.9444444,22.4553831 L50.764731,30.035572 L34.9528265,39.1645793 L18.794342,30.2774129 L33.0555556,22.4553831 L33.0555556,11.8572676 L26.8713692,15.5555556 L25.2777778,12.3899729 L35.008311,6.45378571 L44.7222222,12.3941527 L43.1162391,15.5513757 L36.9444444,11.8580416 Z"
                  />
                </svg>
              </div>}

        {dataSource.url &&
          <div className="component3D-frame">

            <Viewer3D
              hasInteractedWithPlayer={this.hasInteractedWithPlayer}
              isBanner={this.props.isBanner}
              dataSource={dataSource}
              style={style}
              renderer={{
                ratio: 2.5,
                antialias: true,
                showStats: false,
                showProgress: false,
                axesHelper: false,
                ssaaSamples: 4,
                borderStrength: 3.0,
                borderGlow: 0.0,
                borderThickness: 1.0,
                borderPulsePeriod: 5,
                clipPlaneEnabled: false,
                ...rendererCustomSpecs,
              }}
              light={{
                intensity: 2.0,
                color: 'rgb(153,153,153)',
              }}
              camera={{
                near: 50,
                far: 5300,
                scrollZoomToCursor: false,
                enablePan: false,
                enableZoom: false,
                enableRotate: true,
                animationDuration: 750,
                //rotateSpeed: 0.2,
                autoRotateSpeed: 0.1,
                frameOffset: 0,
                selectionFraming: true,
                loadFraming: true,
                maxDistance: Infinity,
                ...cameraCustomSpecs,
              }}
            />

            {!this.props.isBanner &&
              this.props.project3D.length > 1 &&
              <div className="arrow-buttons-component-3d">
                <button
                  type="button"
                  className={`arrow left-arrow-3d-component`}
                  onClick={e => {
                    this.changeDataSource ('next');
                  }}
                >
                  <svg
                    width="50px"
                    height="50px"
                    fill="white"
                    className="shadow"
                  >
                    <path
                      transform="translate(1.000000, 1.000000)"
                      d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
                      id="path-1"
                    />
                    <polygon
                      transform="translate(13.000000, 14.000000)"
                      id="path-3"
                      points="15.2777778 1.22222222 5.5 11 15.2777778 20.7777778 16.6020545 19.453501 8.14855348 11 16.6020545 2.54649896"
                    />
                  </svg>
                </button>

                <button
                  type="button"
                  className={`arrow right-arrow-3d-component`}
                  onClick={e => {
                    this.changeDataSource ('next');
                  }}
                >
                  <svg
                    width="50px"
                    height="50px"
                    fill="white"
                    className="shadow"
                  >
                    <path
                      transform="translate(1.000000, 1.000000)"
                      d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
                      id="path-1"
                    />
                    <polygon
                      transform="translate(15.000000, 14.000000)"
                      id="path-3"
                      points="6.82427674 1.22222222 16.6020545 11 6.82427674 20.7777778 5.5 19.453501 13.953501 11 5.5 2.54649896"
                    />
                  </svg>
                </button>
              </div>}
          </div>}
        {this.props.isBanner &&
          <div
            className="banner-interaction-bottom-blocker"
            onTouchStart={this.props.scrollInteractionSet}
          />}
        {this.props.isBanner
          ? null
          : this.props.messageFor3DComponent &&
              this.props.project3D &&
              <div className="component3D-bottom-bar typo-body2-white-bold shadow">
                <div className="component3D-link-external-wrapper">
                  <div className="component3D-link-external-logo">
                    <svg width="20px" height="20px" fill="white">
                      <path
                        d="M3.33333333,16.6666667 L16.6945847,16.6666667 L16.6945847,11.1111111 L18.3612514,11.1111111 L18.3612514,18.3333333 L16.6945847,18.3333333 L2.22222222,18.3333333 L1.66666667,18.3333333 L1.66666667,2.22222222 L2.22222222,2.22222222 L9.44444444,2.22222222 L9.44444444,3.88888889 L3.33333333,3.88888889 L3.33333333,16.6666667 Z M15.4044011,3.88888889 L12.1943041,3.88888889 L12.1943041,2.22222222 L17.1943041,2.22222222 L18.3333333,2.22222222 L18.3333333,8.33333333 L16.6666667,8.33333333 L16.6666667,4.98364597 L8.26593314,13.3843795 L7.08742184,12.2058682 L15.4044011,3.88888889 Z"
                        id="path-1"
                      />
                    </svg>
                  </div>

                  <div className="component3D-link-external-message">
                    <a
                      className="typo-body2-white-bold"
                      href={this.props.externalLink3DComponent}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.props.messageFor3DComponent}
                    </a>
                  </div>
                </div>
                <div className="component3D-gallery-pagination">
                  {this.state.currentProject + 1}/{this.props.project3D.length}
                </div>
              </div>}
      </div>
    );
  }
}

export default Component3D;
