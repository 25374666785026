export const makeScrollWaypoint = selectorCSS => {
  //classCSS format '.className' or '#id'
  var el = document.querySelector (selectorCSS).getBoundingClientRect ();

  // take 50 to return to accomodate logo size

  return el.top - 50;
};

export const makeScrollWaypointBottom = selectorCSS => {
  //classCSS format '.className' or '#id'

  var elBounding = document
    .querySelector (selectorCSS)
    .getBoundingClientRect ();

  // add 95 to return to accomodate logo size

  var space = elBounding.top - window.innerHeight + 95;

  return space;
};

export const isTouchDevice = () => {
  return !!('ontouchstart' in window || navigator.maxTouchPoints); // works on most browsers  // works on IE10/11 and Surface
};

export const isSmallScreen = () => {
  if (typeof window !== `undefined`)
    var smallScreen =
      window.matchMedia ('(max-width: 767px)').matches ||
      window.matchMedia ('(max-width: 767px) and (orientation: landscape)')
        .matches;

  return smallScreen;
};

export const isLandscape = () => {
  if (typeof window !== `undefined`)
    return window.matchMedia ('(orientation: landscape)').matches;
};

export const isSmallMediumScreen = () => {
  if (typeof window !== `undefined`)
    return window.matchMedia ('(max-width: 991px)').matches;
};

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test (String (email).toLowerCase ());
};
