import React from 'react';
import '../../styles/components/header/header.scss';
import '../../styles/utils/general.scss';
import Navigation from './Navigation';
//import {LocaleConsumer} from '../components/layout';
//import * as routes from '../utils/constants/routes';
//import LocalizedLink from './LocalizedLink';
//import Logo from './Logo';
import HamburguerMenu from './HamburguerMenu';
import LocalizedLink from '../Utils/LocalizedLink';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import * as routes from '../../utils/constants/routes';
import Dock from 'react-dock';
import AuthUserContext from '../Session/AuthUserContext';
import AuthIcon from './AuthIcon';

class Header extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      isOpen: false, //flip hamburguer logo
      isVisible: false, //open or close dock
      signInFormActive: false, //show or hide login form
      hamburguerBlock: false, //block menu interaction
    };
  }

  handleSignInClick = () => {
    let {signInFormActive} = this.state;

    this.setState ({
      signInFormActive: !signInFormActive,
    });
  };

  changeHamburguerStatus = () => {
    let {isOpen, isVisible} = this.state;
    this.setState ({
      isOpen: !isOpen,
      isVisible: !isVisible,
      hamburguerBlock: false,
    });
  };

  changeHamburguerStatusAll = () => {
    let {isOpen, isVisible, signInFormActive} = this.state;
    this.setState ({
      isOpen: !isOpen,
      isVisible: !isVisible,
      signInFormActive: !signInFormActive,
    });
  };

  setOpenSignInForm = () => {
    this.setState ({
      isOpen: true,
      isVisible: true,
      signInFormActive: true,
      hamburguerBlock: true,
    });
  };

  setCloseLoginForm = () => {
    this.setState ({
      isOpen: false,
      isVisible: false,
    });
  };

  componentDidMount () {
    window.addEventListener ('requestLogin', this.setOpenSignInForm);
    window.addEventListener ('closeLogin', this.setCloseLoginForm);
  }

  componentWillUnmount () {
    window.removeEventListener ('requestLogin', this.setOpenSignInForm);
    window.removeEventListener ('closeLogin', this.setCloseLoginForm);
  }

  render () {
    const locale = this.props.locale.locale || this.props.locale;

    const dockStyles = {
      backgroundColor: '#0F0723',
    };

    const logo = this.props.path === '/'
      ? <AnchorLink href="#homepage-first-panel">
          <div className="logo">
            <svg
              width="50px"
              height="45px"
              viewBox="0 0 50 45"
              version="1.1"
              className={'shadow'}
            >
              <path
                fill={this.props.logoColor}
                d="M25,3.77358491 L43.3962264,35.8490566 L31.1321652,35.8490566 L25,25.1569616 L18.8678348,35.8490566 L6.60377358,35.8490566 L25,3.77358491 Z M0,47.1698113 L6.60391857,35.8490566 L13.2075472,47.1698113 L0,47.1698113 Z M18.8679245,35.8490566 L31.1320755,35.8490566 L25.0001346,47.1698113 L18.8679245,35.8490566 Z M36.7924528,47.1698113 L43.3963714,35.8490566 L50,47.1698113 L36.7924528,47.1698113 Z"
              />
            </svg>
          </div>
        </AnchorLink>
      : <LocalizedLink to={routes.LANDING} locale={locale}>
          <div className="logo">
            <svg
              width="50px"
              height="45px"
              viewBox="0 0 50 45"
              version="1.1"
              className={this.props.logoColor === 'white' && 'shadow'}
            >
              <path
                fill={this.props.logoColor}
                d="M25,3.77358491 L43.3962264,35.8490566 L31.1321652,35.8490566 L25,25.1569616 L18.8678348,35.8490566 L6.60377358,35.8490566 L25,3.77358491 Z M0,47.1698113 L6.60391857,35.8490566 L13.2075472,47.1698113 L0,47.1698113 Z M18.8679245,35.8490566 L31.1320755,35.8490566 L25.0001346,47.1698113 L18.8679245,35.8490566 Z M36.7924528,47.1698113 L43.3963714,35.8490566 L50,47.1698113 L36.7924528,47.1698113 Z"
              />
            </svg>
          </div>
        </LocalizedLink>;

    let bodyEl;

    if (typeof window !== `undefined`) {
      bodyEl = document.getElementsByTagName ('body')[0];

      this.state.isOpen
        ? bodyEl.classList.add ('body-activate-menu')
        : bodyEl.classList.remove ('body-activate-menu');
    }
    return (
      <div>
        <div className="header-bar-wrapper">
          {this.props.templateType !== 'LP' && logo}
          <div className="header-menu-interaction-container">
            {this.props.templateType !== 'LP' &&
              <div
                className="hamburguer-menu shadow"
                style={{width: '36px', height: '36px', zIndex: '9999999999'}}
              >

                <HamburguerMenu
                  hamburguerBlock={this.state.hamburguerBlock}
                  color={this.state.isVisible ? 'white' : this.props.logoColor}
                  isOpen={this.state.isOpen}
                  changeHamburguerStatus={this.changeHamburguerStatus}
                />
              </div>}
            <div className="header-auth-icon">
              <AuthUserContext.Consumer>
                {authUser =>
                  authUser &&
                  <AuthIcon
                    authUser={authUser}
                    logoFill={this.props.logoColor}
                  />}
              </AuthUserContext.Consumer>
            </div>
          </div>

        </div>
        <Dock
          position="top"
          isVisible={this.state.isVisible}
          dockStyle={dockStyles}
          fluid={true}
          size={1}
          duration={500}
          zIndex={4}
        >
          <div className="dock-content-wrapper">
            <div className="navigation-menu">
              <Navigation
                locale={locale}
                path={this.props.path}
                handleSignInClick={this.handleSignInClick}
                signInFormActive={this.state.signInFormActive}
                changeHamburguerStatus={this.changeHamburguerStatus}
                changeHamburguerStatusAll={this.changeHamburguerStatusAll}
                authenticationStatus={this.state.authedUser}
                changeAuthenticationStatus={this.changeAuthenticationStatus}
              />
            </div>
          </div>
        </Dock>
      </div>
    );
  }
}

export default Header;

/*
        <LocaleConsumer>
          {locale => {
            return (
              <h1 style={{margin: 0}}>
                <LocalizedLink
                  to={routes.LANDING}
                  locale={locale}
                  style={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  {siteTitle}
                </LocalizedLink>
              </h1>
            );
          }}
        </LocaleConsumer>
        <Navigation />
        */
