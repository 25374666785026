import React from 'react'
import '../../styles/components/case-templates/static-banner.scss'
import Img from 'gatsby-image'
import BannersSkin from '../CaseTemplates/BannersSkin'
import ScrollInteractionLogo from '../Pano/ScrollInteractionLogo'
import '../../utils/helpers'
import {isTouchDevice} from '../../utils/helpers'

const StaticBanner = props => {
  return (
    <div className="banner-container">
      <div className="banner-image-wrapper">
        <Img fluid={props.image.childImageSharp.fluid} />
      </div>
      <div className="banner-text-wrapper">
        {props.title ? (
          <BannersSkin
            image={props.image}
            title={props.title}
            productTypeShort={props.productTypeShort}
            segmentTag={props.segmentTag}
            areaTag={props.areaTag}
            solutionTag={props.solutionTag}
            productType={props.productType}
          />
        ) : (
          <></>
        )}
      </div>
      {typeof window !== `undefined` && isTouchDevice () && 
      <div
        className="banner-interaction-bottom-blocker"
        onTouchStart={() => props.scrollInteractionSet()}
      />
      }
      {props.title && (
        <ScrollInteractionLogo scrollInteraction={props.scrollInteraction} locale={props.locale} scrollInteractionSet={props.scrollInteractionSet} />
      )}
    </div>
  )
}

export default StaticBanner
