import React from 'react';
import '../styles/components/footer.scss';
import '../styles/utils/general.scss';
import LinkedinLogo from '../images/linkedin.svg';
import * as routes from '../utils/constants/routes';
import LocalizedLink from './Utils/LocalizedLink';
import {isSmallScreen} from '../utils/helpers';

const Footer = ({locale}) => {
  const copyrightText = locale === 'en'
    ? 'All rights reserved.'
    : 'Todos os direitos reservados.';

  return (
    <footer>
      {!isSmallScreen ()
        ? <div className="footer-content typo-body2-white">
            <div className="footer-copyright-notice">
              <div>{'2020 © ALIVE VISION by 3Decide. ' + copyrightText}</div>
            </div>
            <div className="contacts-section-footer">
              <div className="footer-social-icons-box">
                <a
                  href="http://www.linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={LinkedinLogo} alt="LinkedinLogo" />
                </a>

              </div>
              <div className="call-to-action-footer button-footer">
                <LocalizedLink to={routes.CONTACTS} locale={locale.locale}>
                  {locale === 'en' ? 'speak with us' : 'fale connosco'}
                </LocalizedLink>

              </div>
            </div>
          </div>
        : <div className="footer-content typo-body2-white">

            <div className="contacts-section-footer">
              <div className="footer-social-icons-box">
                <a
                  href="http://www.linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={LinkedinLogo} alt="LinkedinLogo" />
                </a>

              </div>
              <div className="call-to-action-footer button-footer">
                <LocalizedLink to={routes.CONTACTS} locale={locale.locale}>
                  {locale === 'en' ? 'speak with us' : 'fale connosco'}
                </LocalizedLink>
              </div>
            </div>
            <div className="footer-copyright-notice">
              <div>{'2020 © ALIVE VISION by 3Decide. ' + copyrightText}</div>
            </div>
          </div>}

    </footer>
  );
};

export default Footer;

/*
<div className="footer-social-icons-box">
  <a href="http://www.facebook.com">
    <img src={FacebookLogo} alt="FacebookLogo" />
  </a>

  <a href="http://www.instagram.com">
    <img src={InstagramLogo} alt="InstagramLogo" />
  </a>

  <a href="http://www.linkedin.com">
    <img src={LinkedinLogo} alt="LinkedinLogo" />
  </a>
  <a href="http://www.twitter.com">
    <img src={TwitterLogo} alt="TwitterLogo" />
  </a>

</div>;

*/
