import React from 'react';

export default function ImmersiveandVirtualPresentations (props) {
  const circleFill = props.iconFill;

  const iconFill = props.iconFill === 'white' ? '#0033A0' : 'white';

  return (
    <>
      {!props.isLogoMenu
        ? <svg
            className="immersive-virtual-presentations"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            aria-labelledby="title"
          >
            <title id="title">Immersive and Virtual Presentations</title>
            <path
              fill={circleFill}
              d="M26.64,12.96 L35.28,18.0001107 L26.64,23.04 L26.64,12.96 Z M18,28.08 L9.36,23.0401107 L18,18 L26.64,23.0401107 L18,28.08 Z M18,18 L9.36,12.9601107 L18,7.92 L26.64,12.9601107 L18,18 Z M9.36,12.96 L9.36,23.04 L0.72,18.0001107 L9.36,12.96 Z"
            />
          </svg>
        : <svg
            className="immersive-virtual-presentations shadow-button"
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            aria-labelledby="title"
          >
            <title id="title">Immersive and Virtual Presentations</title>

            <circle
              fill={circleFill}
              cx="24"
              cy="24"
              r="24"
              transform="translate(1.000000, 1.000000)"
            />
            <path
              fill={iconFill}
              d="M26.64,12.96 L35.28,18.0001107 L26.64,23.04 L26.64,12.96 Z M18,28.08 L9.36,23.0401107 L18,18 L26.64,23.0401107 L18,28.08 Z M18,18 L9.36,12.9601107 L18,7.92 L26.64,12.9601107 L18,18 Z M9.36,12.96 L9.36,23.04 L0.72,18.0001107 L9.36,12.96 Z"
              transform="translate(7.000000, 7.000000)"
            />
          </svg>}
    </>
  );
}
