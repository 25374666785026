import React from 'react';

export default function ManagementandLogistics (props) {
  const circleFill = props.iconFill;

  const iconFill = props.iconFill === 'white' ? '#0033A0' : 'white';

  return (
    <>
      {!props.isLogoMenu
        ? <svg
            className="management-and-logistics-solutions"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            aria-labelledby="title"
          >
            <title id="title">Management and Logistics Solutions</title>
            <path
              fill={circleFill}
              d="M9.36,7.92 L18,12.9601107 L9.36,18 L9.36,7.92 Z M0.72,12.96 L9.36,18.0001107 L0.72,23.04 L0.72,12.96 Z M9.36,18 L18,23.0401107 L9.36,28.08 L9.36,18 Z M26.64,7.92 L26.64,18 L18,12.9601107 L26.64,7.92 Z M35.28,12.96 L35.28,23.04 L26.64,18.0001107 L35.28,12.96 Z M26.64,18 L26.64,28.08 L18,23.0401107 L26.64,18 Z"
            />
          </svg>
        : <svg
            className="management-and-logistics-solutions
 shadow-button"
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            aria-labelledby="title"
          >
            <title id="title">Management and Logistics Solutions</title>

            <circle
              fill={circleFill}
              cx="24"
              cy="24"
              r="24"
              transform="translate(1.000000, 1.000000)"
            />
            <path
              fill={iconFill}
              d="M9.36,7.92 L18,12.9601107 L9.36,18 L9.36,7.92 Z M0.72,12.96 L9.36,18.0001107 L0.72,23.04 L0.72,12.96 Z M9.36,18 L18,23.0401107 L9.36,28.08 L9.36,18 Z M26.64,7.92 L26.64,18 L18,12.9601107 L26.64,7.92 Z M35.28,12.96 L35.28,23.04 L26.64,18.0001107 L35.28,12.96 Z M26.64,18 L26.64,28.08 L18,23.0401107 L26.64,18 Z"
              transform="translate(7.000000, 7.000000)"
            />

          </svg>}
    </>
  );
}

/*
<path
  fill={iconFill}
  d="M9.36,7.92 L18,12.9601107 L9.36,18 L9.36,7.92 Z M0.72,12.96 L9.36,18.0001107 L0.72,23.04 L0.72,12.96 Z M9.36,18 L18,23.0401107 L9.36,28.08 L9.36,18 Z M26.64,7.92 L26.64,18 L18,12.9601107 L26.64,7.92 Z M35.28,12.96 L35.28,23.04 L26.64,18.0001107 L35.28,12.96 Z M26.64,18 L26.64,28.08 L18,23.0401107 L26.64,18 Z"
/>;
*/

/*
<svg
  width="36px"
  height="22px"
  viewBox="0 0 36 22"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <defs>
    <path
      d="M9.36,7.92 L18,12.9601107 L9.36,18 L9.36,7.92 Z M0.72,12.96 L9.36,18.0001107 L0.72,23.04 L0.72,12.96 Z M9.36,18 L18,23.0401107 L9.36,28.08 L9.36,18 Z M26.64,7.92 L26.64,18 L18,12.9601107 L26.64,7.92 Z M35.28,12.96 L35.28,23.04 L26.64,18.0001107 L35.28,12.96 Z M26.64,18 L26.64,28.08 L18,23.0401107 L26.64,18 Z"
      id="path-1"
    />
  </defs>
  <g
    id="Symbols"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="ui-/-buttons-/-circle-logopedia-management"
      transform="translate(-7.000000, -14.000000)"
    >
      <g
        id="ui-/-icon-/-logopedia-/-management_50px"
        transform="translate(7.000000, 7.000000)"
      >
        <mask id="mask-2" fill="white">
          <use xlink:href="#path-1" />
        </mask>
        <use
          id="Combined-Shape"
          fill="#0033A0"
          fill-rule="evenodd"
          xlink:href="#path-1"
        />
        <g
          id="color-/-white-/-neutral-main-"
          mask="url(#mask-2)"
          fill="#FFFFFF"
          fill-rule="evenodd"
        >
          <rect id="Rectangle-Copy-3" x="0" y="0" width="36" height="36" />
        </g>
      </g>
    </g>
  </g>
</svg>;

*/
