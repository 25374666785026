import React from 'react';
import AuthUserContext from './AuthUserContext';
import {firebase} from '../../services/Firebase/loginProvider';

const withAuthentication = Component =>
  class WithAuthentication extends React.Component {
    constructor (props) {
      super (props);

      this.state = {
        initFirebase: false,
        authUser: null,
      };

      this._isMounted = false;
    }

    firebaseInit = () => {
      if (firebase && !this.state.initFirebase) {
        firebase.authentication.onAuthStateChanged (authUser => {
          authUser
            ? this.setState (() => ({authUser, initFirebase: true}))
            : this.setState (() => ({
                authUser: null,
                initFirebase: true,
              }));
        });
      }
    };

    componentDidMount () {
      this._isMounted = true;

      this._isMounted && this.firebaseInit ();
    }

    componentDidUpdate () {
      this.firebaseInit ();

      this._isMounted && this.firebaseInit ();
    }

    componentWillUnmount () {
      this._isMounted = false;
    }

    render () {
      const {authUser} = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  };

export default withAuthentication;
