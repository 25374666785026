import React from 'react';
import '../../styles/components/header/hamburgers/hamburgers.scss';
import '../../styles/components/header/hamburguer-menu.scss';

const HamburguerMenu = ({
  isOpen,
  changeHamburguerStatus,
  color,
  hamburguerBlock,
}) => {
  var hamburguerIsActive = isOpen === true ? 'is-active' : null;
  var colorIsBlue = color === '#0033a0' ? 'is-blue' : null;

  return (
    <button
      className={'hamburger hamburger--vortex ' + hamburguerIsActive}
      type="button"
      onClick={hamburguerBlock ? null : changeHamburguerStatus}
    >
      <span className="hamburger-box">
        <span className={'hamburger-inner ' + colorIsBlue} />
      </span>
    </button>
  );
};

export default HamburguerMenu;
