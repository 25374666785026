import {database} from './firebase';

// User API

export const doCreateUser = (id, username, email) => {
  if (!!database.ref (`users/${id}`)) {
    database.ref (`users/${id}`).set ({
      username,
      email,
    });
  } else {
    return true;
  }
};

export const onceGetUsers = () => database.ref ('users').once ('value');
