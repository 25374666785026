import React from 'react';
import LocalizedLink from '../Utils/LocalizedLink';
import '../../styles/components/header/navigation.scss';
import * as routes from '../../utils/constants/routes';
import AuthUserContext from '../Session/AuthUserContext';
import SignOutButton from '../Auth/SignOut';
import {LocaleConsumer} from '../layout';
import SignIn from '../Auth/SignIn';
import '../../styles/utils/general.scss';
import locales from '../../utils/constants/locales';
import {Link} from 'gatsby';

const Navigation = props => {
  return (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser
          ? <NavigationAuth
              path={props.path}
              changeHamburguerStatusAll={props.changeHamburguerStatusAll}
              localeFromProps={props.locale}
            />
          : <NavigationNonAuth
              path={props.path}
              changeHamburguerStatus={props.changeHamburguerStatus}
              changeHamburguerStatusAll={props.changeHamburguerStatusAll}
              handleSignInClick={props.handleSignInClick}
              signInFormActive={props.signInFormActive}
              localeFromProps={props.locale}
            />}
    </AuthUserContext.Consumer>
  );
};

//TODO when enable auth check if closing menu on same path behaves correctly
const NavigationAuth = ({changeHamburguerStatusAll, path, localeFromProps}) => {
  return (
    <nav>
      <LocaleConsumer>
        {locale => {
          return (
            <div className="dock-menu-wrapper">
              <div className="dock-menu-main-links">
                <ul>
                  <li>
                    <LocalizedLink locale={locale.locale} to={routes.LANDING}>
                      {locale.locale === 'en' ? 'HOME' : 'INÍCIO'}
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink
                      locale={locale.locale}
                      to={routes.LANDING}
                      state={{
                        comesFromMenuProjects: true,
                      }}
                      onClick={() => {
                        if (path === routes.LANDING) {
                          var event = new CustomEvent ('navigateToProjects');
                          window.dispatchEvent (event);
                        }
                      }}
                    >
                      {locale.locale === 'en' ? 'PROJECTS' : 'PROJETOS'}
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink
                      locale={locale.locale}
                      to={routes.LANDING}
                      state={{
                        comesFromMenuAboutUs: true,
                      }}
                      onClick={() => {
                        if (path === routes.LANDING) {
                          var event = new CustomEvent ('navigateToAbout');
                          window.dispatchEvent (event);
                        }
                      }}
                    >
                      {locale.locale === 'en' ? 'ABOUT US' : 'SOBRE NÓS'}
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink locale={locale.locale} to={routes.CONTACTS}>
                      {locale.locale === 'en' ? 'CONTACTS' : 'CONTACTOS'}
                    </LocalizedLink>
                  </li>
                </ul>
              </div>
              <ul className="language-switch">
                <li
                  className={
                    locale.locale === 'en' || localeFromProps === 'en'
                      ? 'language-select-underline'
                      : ''
                  }
                >
                  <Link
                    to={
                      locales['en'].default
                        ? path
                        : `/${locales['en'].path}${path}`
                    }
                  >
                    EN
                  </Link>
                </li>
                <li>/</li>
                <li
                  className={
                    locale.locale === 'pt' || localeFromProps === 'pt'
                      ? 'language-select-underline'
                      : ''
                  }
                >
                  <Link
                    to={
                      locales['pt'].default
                        ? path
                        : `/${locales['pt'].path}${path}`
                    }
                  >
                    PT
                  </Link>
                </li>
              </ul>
              <div
                className="dock-menu-login-link button-text-white"
                style={{cursor: 'pointer', color: 'white'}}
              >
                <SignOutButton
                  changeHamburguerStatusAll={changeHamburguerStatusAll}
                />
              </div>
            </div>
          );
        }}
      </LocaleConsumer>
    </nav>
  );
};
const NavigationNonAuth = ({
  handleSignInClick,
  signInFormActive,
  changeHamburguerStatus,
  changeHamburguerStatusAll,
  localeFromProps,
  path,
}) => {
  return signInFormActive === true
    ? <SignIn
        handleSignInVisibility={handleSignInClick}
        changeHamburguerStatus={changeHamburguerStatus}
      />
    : <nav>
        <LocaleConsumer>
          {locale => {
            return (
              <div className="dock-menu-wrapper">
                <div className="dock-menu-main-links">
                  <ul>
                    <li>
                      <LocalizedLink
                        locale={locale.locale}
                        to={routes.LANDING}
                        onClick={() => changeHamburguerStatus ()}
                      >
                        {locale.locale === 'en' ? 'HOME' : 'INÍCIO'}
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink
                        locale={locale.locale}
                        to={routes.LANDING}
                        state={{
                          comeFromMenuProjects: true,
                        }}
                        onClick={() => {
                          if (path === routes.LANDING) {
                            var event = new CustomEvent ('navigateToProjects');
                            window.dispatchEvent (event);
                          }
                        }}
                      >
                        {locale.locale === 'en' ? 'PROJECTS' : 'PROJETOS'}
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink
                        locale={locale.locale}
                        to={routes.LANDING}
                        state={{
                          comesFromMenuAboutUs: true,
                        }}
                        onClick={() => {
                          if (path === routes.LANDING) {
                            var event = new CustomEvent ('navigateToAbout');
                            window.dispatchEvent (event);
                          }
                        }}
                      >
                        {locale.locale === 'en' ? 'ABOUT US' : 'SOBRE NÓS'}
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink
                        locale={locale.locale}
                        to={routes.CONTACTS}
                        onClick={() => changeHamburguerStatus ()}
                      >
                        {locale.locale === 'en' ? 'CONTACTS' : 'CONTACTOS'}
                      </LocalizedLink>
                    </li>
                  </ul>
                </div>
                <ul className="language-switch">
                  <li
                    className={
                      locale.locale === 'en' || localeFromProps === 'en'
                        ? 'language-select-underline'
                        : ''
                    }
                  >
                    <Link
                      to={
                        locales['en'].default
                          ? path
                          : `/${locales['en'].path}${path}`
                      }
                    >
                      EN
                    </Link>
                  </li>
                  <li>/</li>
                  <li
                    className={
                      locale.locale === 'pt' || localeFromProps === 'pt'
                        ? 'language-select-underline'
                        : ''
                    }
                  >
                    <Link
                      to={
                        locales['pt'].default
                          ? path
                          : `/${locales['pt'].path}${path}`
                      }
                    >
                      PT
                    </Link>
                  </li>
                </ul>

              </div>
            );
          }}
        </LocaleConsumer>
      </nav>;
};

export default Navigation;
/*
    {Object.keys(locales).map(key => (
                  <li key={locales[key].locale}>
                    <Link
                      to={
                        locales[key].default
                          ? path
                          : `/${locales[key].path}${path}`
                      }
                    >
                      {locales[key].locale}
                    </Link>
                  </li>
                ))}
*/

/*
<div
  className="dock-menu-login-link button-text-white"
  onClick={handleSignInClick}
  style={{cursor: 'pointer'}}
  locale={locale.locale}
>
  {' '}
  LOG IN
</div>;

*/
