import React from 'react'
import '../../styles/components/header/auth-icon.scss'
import { auth } from '../../services/Firebase/loginProvider'

class AuthIcon extends React.Component {
  state = {
    logout: false,
  }

  handleLogoClick = () => {
    var { logout } = this.state

    this.setState({
      logout: !logout,
    })
  }

  render() {
    return (
      <div className="auth-icon-container">
        <div className="auth-logo" onClick={this.handleLogoClick}>
          <svg width="29px" height="29px">
            <path
              fill={this.props.logoFill}
              d="M14.5,25.7777778 C8.27145532,25.7777778 3.22222222,20.7285447 3.22222222,14.5 C3.22222222,8.27145532 8.27145532,3.22222222 14.5,3.22222222 C20.7285447,3.22222222 25.7777778,8.27145532 25.7777778,14.5 C25.7777778,20.7285447 20.7285447,25.7777778 14.5,25.7777778 Z M14.5,23.3611111 C19.3938565,23.3611111 23.3611111,19.3938565 23.3611111,14.5 C23.3611111,9.60614347 19.3938565,5.63888889 14.5,5.63888889 C9.60614347,5.63888889 5.63888889,9.60614347 5.63888889,14.5 C5.63888889,19.3938565 9.60614347,23.3611111 14.5,23.3611111 Z M14.5,13.6944444 C13.1653119,13.6944444 12.0833333,12.6124659 12.0833333,11.2777778 C12.0833333,9.94308963 13.1653119,8.86111111 14.5,8.86111111 C15.8346881,8.86111111 16.9166667,9.94308963 16.9166667,11.2777778 C16.9166667,12.6124659 15.8346881,13.6944444 14.5,13.6944444 Z M9.66666667,19.3333333 C9.66666667,16.663957 11.8306237,14.5 14.5,14.5 C17.1693763,14.5 19.3333333,16.663957 19.3333333,19.3333333 L9.66666667,19.3333333 Z"
            />
          </svg>
        </div>
        {this.state.logout === true && (
          <div
            className="auth-icon-logout"
            onClick={() => {
              auth.doSignOut()
            }}
          >
            <svg width="37px" height="42px">
              <path
                fill="#CACACA"
                d="M4.83333333,24.1666667 L14.5,24.1666667 L14.5,16.9166667 L16.9166667,16.9166667 L16.9166667,25.7777778 L16.9166667,26.5833333 L3.22222222,26.5833333 L2.41666667,26.5833333 L2.41666667,2.41666667 L3.22222222,2.41666667 L16.9166667,2.41666667 L16.9166667,4.83333333 L16.9166667,11.2777778 L14.5,11.2777778 L14.5,4.83333333 L4.83333333,4.83333333 L4.83333333,24.1666667 Z M22.2311516,12.9958872 L18.9996613,9.76439694 L20.7085027,8.05555556 L25.8350268,13.1820797 L27.002879,14.3499318 L20.7371272,20.6156836 L19.0282858,18.9068422 L22.5225742,15.4125539 L10.8151623,15.4125539 L10.8151623,12.9958872 L22.2311516,12.9958872 Z"
              />
              <text
                id="log-out"
                fontFamily="Nunito-Regular, Nunito"
                fontSize="12"
                fontWeight="normal"
                linespacing="20"
                fill="#CACACA"
              >
                <tspan x="0.478" y="39">
                  log out
                </tspan>
              </text>
            </svg>
          </div>
        )}
      </div>
    )
  }
}

export default AuthIcon
