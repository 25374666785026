import {authentication} from './firebase';
import {database} from './firebase';

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  authentication.signInWithEmailAndPassword (email, password);

//Sign Up

export const doCreateUserWithEmailAndPassword = (email, password) =>
  authentication.createUserWithEmailAndPassword (email, password);

// Sign out
export const doSignOut = () => authentication.signOut ();

// Password Reset
export const doPasswordReset = email =>
  authentication.sendPasswordResetEmail (email);

// Password Change
export const doPasswordUpdate = password =>
  authentication.currentUser.updatePassword (password);

export const doCreateUser = (id, username, email) => {
  database.ref (`users/${id}`).set ({
    username,
    email,
  });
};

export const onceGetUsers = () => database.ref ('users').once ('value');
