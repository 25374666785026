import React from 'react';
import '../../styles/components/pano/scroll-interaction-logo.scss';
import '../../styles/utils/general.scss';

import {isTouchDevice} from '../../utils/helpers';

export default function ScrollInteractionLogo({
  scrollInteraction,
  locale,
  scrollInteractionSet,
}) {
  var scrollCaptionIsHidden = scrollInteraction === true ? 'is-hidden' : '';

  if (typeof window !== `undefined` && isTouchDevice () && scrollInteraction) {
    setTimeout (() => {
      scrollInteractionSet (false);
    }, 5000);
  }

  return (
    <div className={'scroll-indicator ' + scrollCaptionIsHidden + ' shadow'}>
      <div className="typo-captions scroll-captions">
        {typeof window !== `undefined` && !isTouchDevice ()
          ? 'SCROLL'
          : locale === 'en'
              ? 'CLICK AND DRAG HERE TO SCROLL'
              : 'CLIQUE E ARRASTE AQUI PARA SCROLL'}
      </div>
      <div className="scroll-pipe-image"> </div>
    </div>
  );
}
