import React, { Component } from 'react'

import * as routes from '../../utils/constants/routes'
import { auth } from '../../services/Firebase/loginProvider'

import { navigate } from 'gatsby'
import locales from '../../utils/constants/locales'
import '../../styles/components/auth/password-forget.scss'
import '../../styles/utils/general.scss'

const INITIAL_STATE = {
  email: '',
  error: null,
}

class PassForget extends Component {
  state = { ...INITIAL_STATE }

  onSubmit = event => {
    const { email } = this.state

    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }))

        const path = locales[this.props.locale].default
          ? routes.LANDING
          : `/${this.props.locale}${routes.LANDING}`

        navigate(path)
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { email, error } = this.state

    const isInvalid = email === ''

    return (
      <div className="password-forget-form-container">
        <div
          className="close-button"
          onClick={this.props.handleForgetPasswordVisibility}
          style={{ color: 'white' }}
        >
          <svg width="20px" height="20px">
            <polygon
              fill="white"
              points="16.1111111 5.11984127 14.8801587 3.88888889 10 8.76904762 5.11984127 3.88888889 3.88888889 5.11984127 8.76904762 10 3.88888889 14.8801587 5.11984127 16.1111111 10 11.2309524 14.8801587 16.1111111 16.1111111 14.8801587 11.2309524 10"
            />
          </svg>
        </div>
        <div className="password-forget-content-wrapper">
          <div
            className="password-forget-form-title"
            style={{ color: 'white' }}
          >
            {this.props.locale === 'en' ? 'RESET PASSWORD' : 'REPOR PASSWORD'}
          </div>

          <form onSubmit={this.onSubmit}>
            <label htmlFor="input">Email</label>

            <input
              name="email"
              value={this.state.email}
              onChange={event =>
                this.setState({ [event.target.name]: event.target.value })
              }
              type="text"
            />
            {error && (
              <p style={{ marginBottom: 0 }} className="typo-captions-red">
                {error.message}
              </p>
            )}
            <button
              disabled={isInvalid}
              type="submit"
              className="button-primary password-forget-form-button"
            >
              {this.props.locale === 'en' ? 'Reset' : 'REPOR'}
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default PassForget
