import React from 'react';

import {auth} from '../../services/Firebase/loginProvider';

const SignOutButton = props => {
  return (
    <div
      onClick={() => {
        props.changeHamburguerStatusAll ();
        auth.doSignOut ();
      }}
    >
      LOGOUT
    </div>
  );
};

export default SignOutButton;
