import React, {useState, useEffect} from 'react';
import '../../styles/components/case-templates/video-banner.scss';
import BannersSkin from './BannersSkin';
import ScrollInteractionLogo from '../Pano/ScrollInteractionLogo';
import '../../utils/helpers'
import MediaLoader from '../Utils/MediaLoader'
import {isTouchDevice} from '../../utils/helpers'


const VideoBanner = props => {

  const [isLoaderActive, setIsLoaderActive] = useState (true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    var videoEl =
      typeof window !== `undefined` &&
      document.getElementById('video-banner');

    if (videoEl) 
      videoEl.addEventListener('play', () => setIsVideoPlaying(true)) 

    isVideoPlaying && props.videoBannerId && setIsLoaderActive(false) 
});


  
  return (
    <div className="banner-container">
      <div className="banner-video-full-wrapper" style={{ width:"100%", height: "100%" }}>
        

        <div className="background-video" >
          <div className="foreground-video" style={{
    display: !isLoaderActive ? 'block' : 'hidden'
        }}>
            <video
              id="video-banner"
              autoPlay
              //controls
              loop
              muted
              preload="auto"
              playsInline
              style={{ width: `100%`, height: `100%` }}
            >
              <source src={props.videoBannerId} type="video/mp4" />
            </video>
            </div>
</div>


      <div className="banner-text-wrapper">
        {props.title
          ? <BannersSkin
              image={props.image}
              title={props.title}
              productTypeShort={props.productTypeShort}
              segmentTag={props.segmentTag}
              areaTag={props.areaTag}
              solutionTag={props.solutionTag}
              productType={props.productType}
            />
          : <></>}
        </div>
              </div>{ isLoaderActive &&
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
      }}
    >
      <MediaLoader />
    </div>
      }
      {typeof window !== `undefined` && isTouchDevice() &&
        <div
          className="banner-interaction-bottom-blocker"
          onTouchStart={() => props.scrollInteractionSet()}
        />
      }
      {props.title &&
        <ScrollInteractionLogo scrollInteraction={props.scrollInteraction} locale={props.locale} scrollInteractionSet={props.scrollInteractionSet} />}
    </div>
  );
};

export default VideoBanner;

//YOUTUBE CODE
// useEffect(() => {




//   if (typeof window !== `undefined`) {

//     const YouTubeIframeLoader = require('youtube-iframe');



//     YouTubeIframeLoader.load(function (YT) {
//       new YT.Player('player', {
//         videoId: props.videoBannerId,
//         playerVars: {
//           mute: 1,
//           autoplay: 1,
//           loop: 1,
//           controls: 0,
//           showinfo: 0,
//           autohide: 0,
//           enablejsapi: 1,
//           modestbranding: 1,
//           playlist: props.videoBannerId,
//           vq: 'hd1080',
//         },
//         allowfullscreen: 1,
//         events: {
//           onStateChange: onPlayerStateChange,
//         },
//       });
//     });

//     function onPlayerStateChange(el) {
//       if (el.data === 1) {
//         setIsLoaderActive(false);
//       }
//     }
//   }




//()}, []);